<template>
  <div
    class="flex h-full w-full items-center justify-between bg-white p-2 group-hover:bg-slate-100"
  >
    <div class="flex flex-col gap-2 p-2">
      <StartDate />
      <Catalog />
      <NoAccess />
      <Department />
    </div>
  </div>
</template>

<script setup lang="ts">
import StartDate from './element/StartDate.vue'
import Catalog from './element/Catalog.vue'
import NoAccess from './element/NoAccess.vue'
import Department from './element/Department.vue'
</script>
